// Taken from https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints.
$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

@mixin breakpoint($breakpoint: xs) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
    }
}

