html, body {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background-image: url("Background.4e359c8e.png");
  background-repeat: repeat-y repeat-x;
  flex-direction: column;
  font-family: Helvetica, sans-serif;
  font-size: 1.1em;
  line-height: 1.5;
  display: flex;
  position: relative;
}

._5-7o9W_gradient {
  background: linear-gradient(#002e78 25%, #0000 100%);
}

section {
  margin: 0 8px;
}

@media (width >= 576px) {
  section {
    margin: 0 80px;
  }
}

@font-face {
  font-family: Krona-One;
  src: url("KronaOne-Regular.417a04f8.ttf");
}

@font-face {
  font-family: Audiowide;
  src: url("Audiowide-Regular.3e1b313c.ttf");
}

.DK0usq_about {
  margin-bottom: 32px;
}

.DK0usq_about h2 {
  color: #84c1ee;
  text-align: center;
  margin: 0;
  padding-left: 2px;
  font-family: Krona-One;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.DK0usq_about h3 {
  text-align: center;
  margin: 0;
  padding-left: 2px;
  font-family: Krona-One;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.DK0usq_about .DK0usq_columns {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.DK0usq_about .DK0usq_column {
  text-align: left;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 20px;
  display: flex;
}

.DK0usq_about p {
  margin: 8px 8px 50px;
  font-family: Krona-One;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.DK0usq_about p a {
  color: #84c1ee;
}

.DK0usq_about div {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.DK0usq_about div a {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 6px;
  margin: 8px 16px;
  padding: .6em 1.6em;
  text-decoration: none;
}

.DK0usq_about div a svg {
  vertical-align: middle;
  display: inline-block;
}

.DK0usq_about div a:hover {
  text-decoration: underline;
}

.DK0usq_tickets {
  color: #fff;
  background-color: #84c1ee;
}

.DK0usq_discord {
  color: #fff;
  background-color: #7289da;
}

@media (width >= 576px) {
  .DK0usq_about div {
    flex-direction: row;
  }
}

.bYe9eG_committee {
  margin-bottom: 100px;
}

.bYe9eG_committee h2 {
  color: #84c1ee;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
  font-family: Krona-One;
  font-size: 38px;
}

.bYe9eG_committee p, .bYe9eG_committee .bYe9eG_name {
  margin: 0;
  font-family: Krona-One;
  font-size: 16px;
}

.bYe9eG_committee .bYe9eG_function {
  color: #84c1ee;
}

.bYe9eG_committee .bYe9eG_container {
  flex-flow: column wrap;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.bYe9eG_committee .bYe9eG_left, .bYe9eG_committee .bYe9eG_right {
  text-align: left;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  display: flex;
}

.bYe9eG_committee .bYe9eG_right {
  margin-top: 24px;
}

.bYe9eG_committee .bYe9eG_right .bYe9eG_person {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.bYe9eG_committee .bYe9eG_middle {
  width: 100%;
  margin-top: 24px;
}

.bYe9eG_committee .bYe9eG_middle .bYe9eG_person {
  flex-direction: row;
  gap: 20px;
}

.bYe9eG_committee img {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  object-fit: cover;
  width: 90px;
  min-width: 90px;
  height: 100px;
  position: relative;
}

.bYe9eG_committee .bYe9eG_polygon {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background-color: #84c1ee;
  justify-content: center;
  align-items: center;
  width: 100px;
  min-width: 100px;
  height: 110px;
  display: flex;
}

.bYe9eG_committee .bYe9eG_person {
  align-items: center;
  gap: 20px;
  display: flex;
}

@media (width >= 768px) {
  .bYe9eG_committee h2 {
    font-size: 48px;
  }

  .bYe9eG_committee .bYe9eG_container {
    flex-flow: wrap;
  }

  .bYe9eG_committee .bYe9eG_left {
    width: 50%;
  }

  .bYe9eG_committee .bYe9eG_right {
    text-align: right;
    width: 50%;
    margin-top: 0;
  }

  .bYe9eG_committee .bYe9eG_right .bYe9eG_person {
    flex-direction: row;
    justify-content: flex-end;
  }

  .bYe9eG_committee .bYe9eG_middle {
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

  .bYe9eG_committee .bYe9eG_middle .bYe9eG_person {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.ejSxcG_icon {
  align-items: inherit;
  fill: currentColor;
}

.ejSxcG_pad {
  margin: 0 .5em;
}

.yxeYwq_faq h2 {
  color: #db00ff;
  text-align: center;
  margin: 0;
  font-family: Krona-One;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.yxeYwq_faq ul {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  list-style-type: none;
}

.yxeYwq_faq li {
  margin: 32px 0;
}

.yxeYwq_faq .yxeYwq_icon {
  color: #84c1ee;
  font-size: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.yxeYwq_faq details[open] .yxeYwq_icon {
  transform: rotate(180deg);
}

.yxeYwq_faq details {
  border: 3px solid #84c1ee;
  border-radius: 19px;
  padding: 12px 48px;
  position: relative;
}

.yxeYwq_faq summary {
  cursor: pointer;
  text-align: center;
  font-size: 1.17em;
  font-weight: 700;
  list-style: none;
}

.yxeYwq_faq summary::-webkit-details-marker {
  display: none;
}

.yxeYwq_faq p {
  color: #fff;
  text-align: center;
  margin: 8px 0;
  font-size: 1.17em;
  line-height: normal;
}

@media (width >= 768px) {
  .yxeYwq_faq {
    border-radius: 6px;
  }

  .yxeYwq_faq .yxeYwq_icon {
    top: unset;
  }
}

.aIuOea_footer {
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-bottom: 20px;
  display: flex;
}

.aIuOea_footer .aIuOea_container {
  flex-direction: column;
  gap: 16px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.aIuOea_footer p {
  color: #84c1ee;
  margin: 0;
  font-family: Krona-One;
  font-size: 16px;
}

.aIuOea_footer a {
  color: #fff;
  font-family: Krona-One;
  font-size: 16px;
  text-decoration: none;
}

.aIuOea_footer .aIuOea_innercontainer {
  flex-direction: column;
  width: 30%;
  display: flex;
}

@media (width >= 768px) {
  .aIuOea_footer .aIuOea_container {
    flex-direction: row;
    gap: 0;
  }
}

.nTHCOW_header {
  background-image: linear-gradient(#0000 0%, #002e78 100%), url("banner.071e9473.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  display: flex;
}

.nTHCOW_header .nTHCOW_container {
  flex-direction: column;
  place-content: center;
  width: 100%;
  max-width: 700px;
  margin: auto;
  display: flex;
}

.nTHCOW_header .nTHCOW_text {
  justify-content: space-between;
  margin: 0 30px;
  display: flex;
}

.nTHCOW_header .nTHCOW_buttons {
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.nTHCOW_header a {
  color: #002e78;
  background-color: #84c1ee;
  border: 1px solid #002e78;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin-bottom: 16px;
  padding: 10px 15px;
  font-family: Krona-One;
  font-size: 18px;
  text-decoration: none;
  display: flex;
}

.nTHCOW_header a:hover {
  cursor: pointer;
  background-color: #389ee9;
}

.nTHCOW_header button.nTHCOW_secondary {
  color: #84c1ee;
  background-color: #002e78;
  border: 1px solid #84c1ee;
}

.nTHCOW_header p {
  color: #84c1ee;
  font-family: Krona-One;
  font-size: 24px;
}

.nTHCOW_header small {
  color: #84c1ee;
  font-family: Krona-One;
  font-size: 14px;
}

.T9eJBq_numbers {
  margin-bottom: 64px;
}

.T9eJBq_numbers h2 {
  color: #db00ff;
  text-align: center;
  margin: 0;
  padding-left: 2px;
  font-family: Audiowide;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.T9eJBq_numbers h3 {
  text-align: center;
  margin: 0;
  padding-left: 2px;
  font-family: Krona-One;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.T9eJBq_numbers .T9eJBq_columns {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.T9eJBq_numbers .T9eJBq_column {
  text-align: left;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 20px;
  display: flex;
}

.T9eJBq_numbers div {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.T9eJBq_numbers div a {
  white-space: nowrap;
  cursor: pointer;
  border-radius: 6px;
  margin: 8px 16px;
  padding: .6em 1.6em;
  text-decoration: none;
}

.T9eJBq_numbers div a svg {
  vertical-align: middle;
  display: inline-block;
}

.T9eJBq_numbers div a:hover {
  text-decoration: underline;
}

@media (width >= 576px) {
  .T9eJBq_numbers div {
    flex-direction: row;
  }
}

.gNJU5G_photos {
  margin-bottom: 32px;
}

.gNJU5G_photos h2 {
  color: #84c1ee;
  text-align: center;
  margin-bottom: 32px;
  padding-left: 2px;
  font-family: Krona-One;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.gNJU5G_photos p {
  margin: 0 8px;
}

.gNJU5G_galleryContainer {
  position: relative;
  overflow: hidden;
}

.gNJU5G_galleryContainer:before, .gNJU5G_galleryContainer:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(to right, #0000, #000);
  width: 30%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.gNJU5G_galleryContainer:before {
  background: linear-gradient(to left, #0000, #000);
  left: 0;
}

.gNJU5G_galleryContainer:after {
  right: 0;
}

.gNJU5G_gallery {
  scrollbar-width: auto;
  gap: 8px;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.gNJU5G_gallery::-webkit-scrollbar {
  height: 8px;
}

.gNJU5G_gallery::-webkit-scrollbar-track {
  background: #303030;
}

.gNJU5G_gallery::-webkit-scrollbar-thumb {
  background-color: #646464;
}

.gNJU5G_image {
  cursor: pointer;
}

.gNJU5G_image img {
  object-fit: cover;
  width: 400px;
  height: 260px;
}

.gNJU5G_fullimage {
  backdrop-filter: blur(12px);
  z-index: 2;
  background-color: #181226bb;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.gNJU5G_fullimage .gNJU5G_navigation {
  pointer-events: none;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.gNJU5G_fullimage .gNJU5G_arrow {
  color: #fff;
  cursor: pointer;
  pointer-events: auto;
  font-size: 72px;
}

.gNJU5G_fullimage .gNJU5G_arrow:hover {
  color: #84c1ee;
}

.gNJU5G_fullimage img {
  width: auto;
  max-width: 90vw;
  height: auto;
  max-height: 90vh;
}

.jRrIfW_sponsors {
  margin-bottom: 32px;
}

.jRrIfW_sponsors h2 {
  color: #84c1ee;
  text-align: center;
  margin-bottom: 64px;
  padding-left: 2px;
  font-family: Krona-One;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.jRrIfW_sponsors h3 {
  color: #84c1ee;
  text-align: center;
  margin-bottom: 64px;
  padding-left: 2px;
  font-family: Krona-One;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.jRrIfW_sponsorlist {
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 16px 32px;
  padding: 0;
  display: flex;
}

.jRrIfW_sponsorlist li {
  justify-content: center;
  width: 66%;
  list-style: none;
}

.jRrIfW_sponsorlist .jRrIfW_topbar hr {
  color: #84c1ee;
  background-color: #84c1ee;
  width: 100%;
  height: 2px;
  margin-bottom: 10px;
}

.jRrIfW_sponsorlist .jRrIfW_bottombar hr {
  color: #84c1ee;
  background-color: #84c1ee;
  width: 75%;
  height: 2px;
  margin-top: 10px;
}

.jRrIfW_sponsorlist .jRrIfW_img {
  padding: 8px;
  display: flex;
}

.jRrIfW_sponsorlist .jRrIfW_img img {
  object-fit: contain;
  width: 100%;
  height: 120px;
}

.jRrIfW_sponsorlist a {
  text-decoration: none;
}

.jRrIfW_sponsorlist p {
  color: #fff;
  text-align: center;
  font-family: Krona-One;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.jRrIfW_sponsorlist [data-type="organisation"] > * {
  color: #00a6d6;
}

.jRrIfW_sponsorlist [data-type="primary"] > * {
  color: #84c1ee;
}

.jRrIfW_sponsorlist [data-type="secondary"] > * {
  color: #db00ff;
}

@media (width >= 576px) {
  .jRrIfW_sponsorlist li {
    width: 50%;
  }
}

@media (width >= 768px) {
  .jRrIfW_sponsorlist li {
    width: 33%;
  }
}

@media (width >= 1200px) {
  .jRrIfW_sponsorlist li {
    width: 20%;
  }
}

.pHzBaG_timeline {
  align-self: center;
  margin-bottom: 32px;
}

.pHzBaG_timeline h2 {
  color: #db00ff;
  text-align: center;
  margin: 0;
  font-family: Krona-One;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pHzBaG_timeline p {
  text-align: center;
  margin: 0;
}

.pHzBaG_timeline .pHzBaG_days {
  display: none;
}

.pHzBaG_timeline .pHzBaG_container {
  flex-direction: column;
  display: flex;
}

.pHzBaG_timeline .pHzBaG_container > div {
  grid-template-rows: repeat(10, 200px);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.pHzBaG_timeline .pHzBaG_container h3 {
  color: #db00ff;
  grid-column: 1 / 3;
  margin: 4px 16px;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_mobiledays {
  color: #fff;
  margin: 16px 8px;
  font-family: Krona-One;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_saturday {
  width: 50%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_saturday .pHzBaG_timelineEntry:first-child {
  grid-column-start: 2;
  grid-row-start: 0;
  grid-row-end: 1;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_saturday .pHzBaG_timelineEntry:nth-child(2) {
  grid-row: 1 / 2;
  grid-column-start: 1;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_saturday .pHzBaG_timelineEntry:nth-child(3) {
  grid-row: 2 / 12;
  grid-column-start: 2;
  height: 100%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_saturday .pHzBaG_timelineEntry:nth-child(3) div {
  height: 100%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_saturday .pHzBaG_timelineEntry:nth-child(4) {
  grid-row: 6 / 8;
  grid-column-start: 1;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_saturday .pHzBaG_timelineEntry:nth-child(5) {
  grid-row: 9 / 10;
  grid-column-start: 1;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_sunday {
  width: 50%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_sunday .pHzBaG_timelineEntry:first-child {
  grid-row: 1 / 6;
  grid-column-start: 2;
  height: 100%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_sunday .pHzBaG_timelineEntry:nth-child(2) {
  grid-row: 2 / 3;
  grid-column-start: 1;
  height: 100%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_sunday .pHzBaG_timelineEntry:nth-child(3) {
  grid-row: 4 / 7;
  grid-column-start: 1;
  height: 100%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_sunday .pHzBaG_timelineEntry:nth-child(4) {
  grid-row: 6 / 8;
  grid-column-start: 2;
  height: 100%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_sunday .pHzBaG_timelineEntry:nth-child(5) {
  grid-row: 8 / 9;
  grid-column-start: 1;
  height: 100%;
}

.pHzBaG_timeline .pHzBaG_container .pHzBaG_sunday .pHzBaG_timelineEntry:nth-child(6) {
  grid-row-start: 7;
  grid-column-start: 1;
}

.pHzBaG_timelineEntry {
  flex-direction: column;
  margin: 0 8px;
  display: flex;
}

.pHzBaG_timelineEntry time {
  font-style: italic;
}

.pHzBaG_timelineEntry time svg {
  padding-right: 4px;
}

.pHzBaG_timelineEntry div {
  border: 2px solid #84c1ee;
  flex-grow: 1;
  padding: 10px;
  font-family: Krona-One;
  font-size: 14px;
}

.pHzBaG_timelineEntry h4 {
  color: #84c1ee;
  margin: 0 0 8px;
  font-size: 16px;
}

.pHzBaG_timelineEntry h4 svg {
  padding-right: 4px;
}

@media (width >= 1200px) {
  .pHzBaG_timeline .pHzBaG_container {
    flex-direction: row;
  }

  .pHzBaG_timeline .pHzBaG_container .pHzBaG_mobiledays {
    display: none;
  }

  .pHzBaG_saturday {
    border-right: 2px solid #84c1ee;
    padding-right: 75px;
  }

  .pHzBaG_sunday {
    padding-left: 75px;
  }

  .pHzBaG_timeline .pHzBaG_days {
    flex-direction: row;
    display: flex;
  }

  .pHzBaG_timeline .pHzBaG_days h3 {
    width: 50%;
    min-width: calc(50% - 16px);
    max-width: 50%;
    margin: 24px 8px;
    font-family: Krona-One;
    font-size: 20px;
    font-weight: 400;
  }

  .pHzBaG_timeline .pHzBaG_days h3:nth-child(2) {
    margin-left: 82px;
  }
}

@media (width >= 768px) {
  .pHzBaG_timeline .pHzBaG_container > div {
    grid-template-rows: repeat(10, 150px);
  }
}

.Smfnra_navigation {
  border: 5px solid #84c1ee;
  display: none;
}

.Smfnra_navigation > div {
  gap: 16px;
  margin: 4px 8px;
  font-family: Krona-One;
  font-size: 20px;
  display: flex;
}

.Smfnra_navigation > div .Smfnra_time {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
  display: flex;
}

.Smfnra_navigation > div .Smfnra_icon {
  color: #db00ff;
  vertical-align: middle;
  margin: 0 8px;
  display: flex;
}

@media (width >= 992px) {
  .Smfnra_navigation {
    display: block;
  }
}

.--8v_q_timer {
  display: flex;
}

.--8v_q_timer > div {
  gap: 0;
}

.--8v_q_timer span {
  color: #db00ff;
  margin-left: -4px;
  margin-right: 8px;
}
/*# sourceMappingURL=index.83263601.css.map */
